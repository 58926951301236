<template>
  <b-overlay
    :show="loading"

    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Контактная информация"
              label-for="contact_information"
            >
              <b-form-textarea
                id="contact_information"
                v-model.trim="item.contact_information"
                placeholder="Контактная информация"
                :state="get(validation, 'contact_information[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'contact_information[0]')"
                class="text-danger"
              >
                {{ get(validation, 'contact_information[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <div class="d-flex mt-2 mb-1">
              <feather-icon
                icon="CalendarIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                График работы по дням недели
              </h4>
            </div>
          </b-col>
          <b-col
            v-for="day in weekDays"
            :key="day.key"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="day.label"
              :label-for="day.key"
            >
              <b-form-input
                :id="day.key"
                v-model.trim="item.working_schedule[day.key]"
                placeholder="График работы"
                :state="get(validation, `working_schedule.${day.key}[0]`) ? false : null"
              />
              <small
                v-if="!!get(validation, `working_schedule.${day.key}[0]`)"
                class="text-danger"
              >
                {{ get(validation, `working_schedule.${day.key}[0]`) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="my-1"
          >
            <address-field v-model="item.real_address" />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BFormTextarea, BCardHeader,
  BCardTitle, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import get from 'lodash/get'
import { getCurrentInstance } from 'vue'
import { createWarehouse } from '@/services/main-api/shop/warehouses'
import useCrudCreate from '@/composables/useCrudCreate'
import AddressField from '@/components/AddressField.vue'
import { createAddress, updateAddress } from '@/services/main-api/geolocation/addresses'

export default {
  name: 'CreateWarehouseView',
  components: {
    AddressField,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
  },
  setup() {
    const instance = getCurrentInstance().proxy
    const weekDays = [
      { label: 'Понедельник', key: 'monday' },
      { label: 'Вторник', key: 'tuesday' },
      { label: 'Среда', key: 'wednesday' },
      { label: 'Четверг', key: 'thursday' },
      { label: 'Пятница', key: 'friday' },
      { label: 'Суббота', key: 'saturday' },
      { label: 'Воскресенье', key: 'sunday' },
    ]

    const createEntity = async () => {
      const res = await createWarehouse({
        project_id: instance.$store.getters['workingMode/selected_project_id'],
        name: instance.item.name,
        contact_information: instance.item.contact_information,
        working_schedule: {
          monday: instance.item.working_schedule.monday,
          tuesday: instance.item.working_schedule.tuesday,
          wednesday: instance.item.working_schedule.wednesday,
          thursday: instance.item.working_schedule.thursday,
          friday: instance.item.working_schedule.friday,
          saturday: instance.item.working_schedule.saturday,
          sunday: instance.item.working_schedule.sunday,
        },
      })
      const warehouse = res.data.data
      const resCreateAddress = await createAddress({
        ...instance.item.real_address,
        company_id: instance.$store.getters['workingMode/selected_company_id'],
      })
      const address = resCreateAddress.data.data
      updateAddress({
        id: address.id,
        model: 'warehouse',
        model_id: warehouse.id,
        model_field: 'real_address',
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        name: '',
        contact_information: '',
        working_schedule: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
        },
        real_address: {
        },
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      createEntity,

      getTextAfterCreation: () => 'Склад был создан.',
      runAfterCreation: async () => {
        await instance.$router.push({ name: 'warehouses' })
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,

      checkValidation,
      clearForm,
      catchValidation,
      createItem,
      get,

      weekDays,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
